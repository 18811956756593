/* eslint-disable react/prop-types */
import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
} from '@mui/material';
import countryList from 'react-select-country-list'
import VariantsContainer from './Variant/variantsContainer'
import { CollectionApi } from '../../api/collectionApi'
import { ProductApi } from '../../api/productApi';
import { useDispatch } from 'react-redux';
import { getProduct } from '../../actions/productActions';
import { setSuccessNotification } from '../../actions/notificationAction';
import '../Collection/Collection.css'
import EditPageLowerContainer from '../Shared/EditPageLowerContainer';
import { ProductInfo, ProductVisibility, ProductLogs, ProductCollections, ProductMedia } from '../../shared-components';
import useTranslation from '../../hooks/useTranslation'
import AuthStorage from '../../services/authStorage'

const EditProduct = props => {
  const dispatch = useDispatch()
  const Auth = new AuthStorage()
  const [term, setTerm] = useState('');
  const [collections, setCollections] = useState([])
  const [searchRequestStatus, setSearchRequestStatus] = useState('pending')
  const [selectedImages, setSelectedImages] = useState([])
  const [hasVariants, setHasVariants] = useState(props.product.has_variants);
  const [isBundle, setIsBundle] = useState(props.product.is_bundle ?? false);
  const [productUnitPrice, setProductUnitPrice] = useState(props.product.price ?? '');
  const options = useMemo(() => countryList().getData(), [])
  const [productCollections, setProductCollections] = useState([])
  const productCollectionIds = productCollections?.map(collection => collection.id);
  const storeUrl = Auth.getStoreUrl(`/${props.product.category_slug}/${props.product.slug}`)
  const { t } = useTranslation()

  const handleSelectedImagesUpdate = (event, image) => {
    let images = [...selectedImages]
    if (event.target.checked){
      images.push(image.id)
    } else {
      images = images.filter(img => img !== image.id)
    }
    setSelectedImages([...images])
  }

  useEffect(() => {
    setHasVariants(props.product.has_variants);
  }, [props.product.has_variants]);

  useEffect(() => {
    setIsBundle(props.product.is_bundle);
  }, [props.product.is_bundle]);

  useEffect(() => {
    setProductUnitPrice(props.product.price);
  }, [props.product.price]);
  

  useEffect(()=>{
    if (selectedImages) {
      setSelectedImages([])
    }  
  },[props.setSelectedImages])
  
  useEffect(() => {
    setProductCollections(props.product.collections)
  }, [props.product.collections])

  const fetchCollections = () => {
    CollectionApi.searchCollections(term)
      .then(({status, data}) => {
        if( status === 200 ) {
          if ( data?.data?.length === 0 ){
            setSearchRequestStatus('No Collections Found')
          }
          let selectedProducts = data?.data.filter(collection => !productCollectionIds?.includes(collection.id) )
          setCollections(selectedProducts)
        }
      })
  }

  const addProductToCollection = async (collectionId, actionType) => {
    let updatedProductCollectionsIds = [...productCollectionIds];
    if (actionType === 'add') {
      updatedProductCollectionsIds.push(collectionId);
    } else {
      updatedProductCollectionsIds = updatedProductCollectionsIds.filter(id => id !== collectionId);
    }
    const response = await ProductApi.addToCollection({ collectionIds: updatedProductCollectionsIds, productId: props.product.id });
    if (response?.status === 200) {
      if (actionType === 'add') {
        const filteredCollections = collections.filter(collection => collection.id === collectionId);
        setProductCollections([...response.data, ...filteredCollections]);
        setCollections(collections.filter(collection => collection.id !== collectionId));
        dispatch(setSuccessNotification({ message: 'Product is added to the Collection' }));
      } else {
        setProductCollections(response.data.filter(collection => collection.id !== collectionId));
        dispatch(setSuccessNotification({ message: 'Product is removed from the Collection' }));
      }
      dispatch(getProduct(props.product.id));
    }
  };

  // Translation function
  const translateText = (text) => {
    return t(text);
  };

  const leftFirstContainer = () => (
    <ProductInfo {...props} storeUrl={Auth.getStoreUrl()} hasVariants={hasVariants} options={options} translateText={translateText} />
  )

  const rightFirstContainer = () => (
    <ProductVisibility {...props} storeUrl={storeUrl} translateText={translateText} />
  )

  const rightSecondContainer = () => (
    <ProductCollections
      productCollections={productCollections}
      productCollectionsIds={productCollectionIds}
      addProductToCollection={addProductToCollection}
      fetchCollections={fetchCollections}
      collections={collections}
      term={term}
      setTerm={setTerm}
      translateText={translateText}
      searchRequestStatus={searchRequestStatus}
    />
  )
  const handleDelete = () => {
    props.handleBulkImageDeletion(selectedImages)
  }; 

  const leftSecondContainer = () => (
    <ProductMedia
      product={props.product}
      updateImage={props.updateImage}
      sortImages={props.sortImages}
      newImage={props.newImage}
      removeImage={props.removeImag}
      handleSelectedImagesUpdate={handleSelectedImagesUpdate}
      selectedImages={selectedImages}
      searchRequestStatus={searchRequestStatus}
      handleBulkImageDeletion={handleDelete}
    />
  )

  const leftThirdContainer = () => (
    <Grid>
      <VariantsContainer
        productImages={props.product.images}
        onProductChange={props.onChange}
        hasVariants={hasVariants}
        setHasVariants={setHasVariants}
        isBundle={isBundle}
        setIsBundle={setIsBundle}
        unitPrice={productUnitPrice}
        variants={props.product.variants}
        onUpdate={props.updateVariant}
        sortArray={props.sortVariants}
        newVariant={props.newVariant}
        type={'variants'}
        pageType={props.product > 0 ? 'Edit' : 'New'}
        fulfillmentCenter={props.product.fulfillmentCenter}
      />
    </Grid>
  )

  const rightThirdContainer = () => (
      <ProductLogs {...props} translateText={translateText} />
  )

  const rightFourthContainer = () => (
    <>
      {props.product.fulfillment_center_id && (
        <>
          <h4>{t('labels.fulfillment_center')}</h4>
          <div style={{marginTop: '20px'}}>
            {props.product.fulfillmentCenter?.name}
          </div>
        </>
      )}
    </>
  )

  return(
    <form onSubmit={props.onSave}>
      <EditPageLowerContainer
        leftContent={ [leftFirstContainer, leftSecondContainer, leftThirdContainer] }
        rightContent={ props.product.id > 0 ? [rightFirstContainer, rightSecondContainer, rightThirdContainer, rightFourthContainer] : [rightFirstContainer, rightThirdContainer] }
        actionType={props.product.id > 0 ? 'Edit' : 'New'}
      />
    </form>
  )
}

EditProduct.propTypes = {
  product: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  validationErrors: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  saving: PropTypes.bool,
  updateVariant: PropTypes.func.isRequired,
  updateImage: PropTypes.func.isRequired,
  onDescriptionChange: PropTypes.func.isRequired,
  newImage: PropTypes.func,
  sortVariants: PropTypes.func,
  newVariant: PropTypes.func
}

export default EditProduct
