import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { TableCell, TableRow, IconButton } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import StatusChip from '../Helpers/StatusChip'
import useTranslation from '../../hooks/useTranslation'

const Order = (props) => {
  const { t } = useTranslation()
  return (
    <TableRow
      key={props.id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
      <TableCell component="th" scope="row">
        <Link to={`/admin/orders/${props.id}`}>#{props.id}</Link>
      </TableCell>
      <TableCell align="left">{(new Date(props.createdAt)).toDateString('sv')}</TableCell>
      <TableCell align="left">{props.user?.name || props.name || props.shipping_name}</TableCell>
      <TableCell align="left">{props.shipping_city}</TableCell>
      <TableCell align="left">{props.shipping_country}</TableCell>
      <TableCell align="left">{`${props.total || '0'} ${props.storeCurrency.symbol}`}</TableCell>
      <TableCell align="left"><StatusChip status={t(props.payment_status)} /></TableCell>
      <TableCell align="left"><StatusChip status={t(props.status)} /></TableCell>
      <TableCell align="center">
        {props.order_fulfillment_id && (
          <Link to={`/admin/fulfillment-center-orders/${props.order_fulfillment_id}`}>
            <IconButton color="secondary" size="small">
              <LocalShippingIcon color='primary' />
            </IconButton>
          </Link>
        )}
      </TableCell>
      <TableCell>
        <Link to={`/admin/orders/${props.id}`}>
          <IconButton color="primary" size="small"><VisibilityIcon /></IconButton>
        </Link>
      </TableCell>
    </TableRow>
  )
}

Order.propTypes = {
  id: PropTypes.number.isRequired,
  shipping_name: PropTypes.string,
  shipping_city: PropTypes.string,
  shipping_country: PropTypes.string,
  net_total: PropTypes.string,
  payment_status: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  storeCurrency: PropTypes.object.isRequired
}

export default Order
